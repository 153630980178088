@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ais-Hits-item {
  border: none;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.ais-Hits-list {
  margin: 0px;
}

.ais-SearchBox-submitIcon path {
  fill: #003d5b;
}

.ais-SearchBox-resetIcon path,
.ais-SearchBox-submitIcon path {
  fill: #003d5b;
}

.ais-SearchBox-input {
  border: 1px solid rgb(199, 199, 204);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
html {
  scroll-behavior: smooth;
}
p {
}
h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
a {
  color: black;
}

